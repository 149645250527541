import 'jquery.easing'
import 'jquery-colorbox'
import 'jscrollpane'
import 'jquery-mousewheel'
import Swiper from 'swiper'
import 'tinycolor2'
import '@web-nfb/frontend-static/src/js/custom/modules/m-modal.js'
import '@web-nfb/frontend-static/src/js/custom/shared_functions/sf-collapseBlock.js'
import { sf_initSwiper } from '@web-nfb/frontend-static/src/js/custom/shared_functions/sf-initSwiper.js'
import '../layout/layoutPublic.js'
globalThis.sf_initSwiper = sf_initSwiper
globalThis.Swiper = Swiper
